// src/components/UpgradeModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react'; // Using Lucide React for consistent icons

const UpgradeModal = ({ isOpen, onClose, onUpgrade }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="upgrade-modal-title"
      aria-describedby="upgrade-modal-description"
    >
      <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 focus:outline-none"
          aria-label="Close Modal"
        >
          <X className="w-5 h-5" /> {/* Icon from Lucide React */}
        </button>

        {/* Modal Content */}
        <div className="text-center">
          <div className="text-pink-500 text-3xl mb-4">👑</div>
          <h2
            id="upgrade-modal-title"
            className="text-xl font-semibold text-gray-800 mb-4"
          >
            Upgrade to Premium
          </h2>
          <p
            id="upgrade-modal-description"
            className="text-gray-600 mb-6"
          >
            Get access to our powerful AI Seating Planner and more premium features:
          </p>
          <ul className="text-left text-gray-600 mb-6 space-y-2">
            <li>👑 AI-powered seating optimization</li>
            <li>👑 Relationship mapping</li>
            <li>👑 Guest conflict management</li>
          </ul>
          <div className="text-lg font-semibold text-gray-800 mb-4">$5/month</div>
          <p className="text-sm text-gray-500 mb-6">Cancel anytime</p>
          <button
            onClick={onUpgrade}
            className="w-full bg-pink-500 text-white py-2 rounded-lg hover:bg-pink-600 transition"
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
};

UpgradeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
};

export default UpgradeModal;
