import React from 'react';
import { Helmet } from 'react-helmet';

export const SEO = ({ 
  title = 'Seat Your Guests - Wedding Seating Chart Planner',
  description = 'Create beautiful wedding seating charts with our AI-powered planner. Easy to use, drag-and-drop interface, and smart seating arrangements.',
  path = ''
}) => {
  const siteUrl = 'https://www.seatyourguests.com';
  const url = `${siteUrl}${path}`;
  // Generate OG image URL with title parameter
  const ogImageUrl = `${siteUrl}/api/og?title=${encodeURIComponent(title)}`;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Enhanced Keywords */}
      <meta name="keywords" content="wedding seating chart, wedding table planner, seating arrangement tool, wedding planning software, wedding guest layout, reception seating planner, AI wedding planner, table arrangement app, wedding seating optimizer, free wedding seating chart maker" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Seat Your Guest" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImageUrl} />
      <meta name="twitter:creator" content="@seatyourguest" />

      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="Seat Your Guest" />
      <meta name="theme-color" content="#FDF8F0" />
      
      {/* Mobile Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      
      {/* Preconnect to Important Origins */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={url} />
      
      {/* Alternative Language Versions */}
      <link rel="alternate" href={url} hrefLang="x-default" />
      <link rel="alternate" href={url} hrefLang="en" />
    </Helmet>
  );
};
