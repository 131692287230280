// App.jsx

import React, { useState, useEffect, Suspense, lazy } from "react";
import { useStore } from "./store/useStore";
import { EventSelector } from "./components/EventSelector";
import { TableEditor } from "./components/TableEditor";
import { LandingPage } from "./components/LandingPage";
import { FloorPlan } from "./components/FloorPlan";
import { AISeatingPlanner } from "./components/AISeatingPlanner";
import { AuthProvider } from "./contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { MainHeader } from "./components/MainHeader";
import { SEO } from "./components/SEO";
import { StructuredData } from "./components/StructuredData";
import LoadingSpinner from './components/LoadingSpinner';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

// Import Subscription Pages
import SubscribePage from "./pages/SubscribePage";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";

const stripePromise = loadStripe(process.env.VITE_STRIPE_PUBLIC_KEY);

// Lazy load components that aren't needed for initial render
const FloorPlanLazy = lazy(() => import('./components/FloorPlan'));
const AISeatingPlannerLazy = lazy(() => import('./components/AISeatingPlanner'));

function AppContent() {
  const { currentEvent, setCurrentEvent } = useStore();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleReset = () => {
    if (window.confirm("Are you sure you want to reset? This will clear all data.")) {
      localStorage.clear();
      setCurrentEvent(null);
      window.location.reload();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FDF8F0] to-white">
      <MainHeader
        showResetButton={!!currentEvent}
        onReset={handleReset}
      />

      <main className="pt-[160px]">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <SEO 
                  title="Seat Your Guest - AI Wedding Seating Chart Planner"
                  description="Create perfect wedding seating arrangements with our AI-powered planner. Free, easy-to-use, and beautifully designed for your special day."
                  path="/"
                />
                <LandingPage />
              </>
            }
          />
          <Route
            path="/planner"
            element={
              <>
                <SEO 
                  title="Wedding Table Planner - Design Your Layout | Seat Your Guest"
                  description="Design your wedding seating layout with our intuitive planner. Multiple table styles, drag-and-drop interface, and smart guest management."
                  path="/planner"
                />
                {!currentEvent ? <EventSelector /> : <TableEditor isMobileView={isMobileView} />}
              </>
            }
          />
          <Route
            path="/floorplan"
            element={
              <>
                <SEO 
                  title="Wedding Floor Plan Designer | Seat Your Guest"
                  description="Visualize your wedding venue layout with our interactive floor plan designer. Arrange tables, manage guest seating, and create the perfect setup."
                  path="/floorplan"
                />
                <Suspense fallback={<LoadingSpinner />}>
                  <FloorPlanLazy />
                </Suspense>
              </>
            }
          />
          <Route
            path="/ai-planner"
            element={
              <>
                <SEO 
                  title="AI Wedding Seating Optimizer | Seat Your Guest"
                  description="Let our AI create the optimal seating arrangement for your wedding. Considers relationships, preferences, and table dynamics."
                  path="/ai-planner"
                />
                <Suspense fallback={<LoadingSpinner />}>
                  <AISeatingPlannerLazy />
                </Suspense>
              </>
            }
          />
          <Route path="/subscribe" element={
            <>
              <SEO 
                title="Premium Features - Wedding Seating Planner | Seat Your Guest"
                description="Unlock advanced features like AI seating optimization, unlimited guests, and premium table layouts."
                path="/subscribe"
              />
              <SubscribePage />
            </>
          } />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Toaster position="top-right" />
      {/* Preload critical images */}
      <link rel="preload" as="image" href="/hero-image.jpg" />
    </div>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <StructuredData />
          <div>
            <AppContent />
            <SpeedInsights />
            <Analytics />
          </div>
        </Router>
      </Elements>
    </AuthProvider>
  );
}