import React, { useState } from 'react';
import {
  Plus,
  Minus,
  ArrowLeft,
  Save,
  Mail,
  Download,
  Grid,
  Brain,
} from 'lucide-react';
import { useStore } from '../store/useStore';
import { FloorPlan } from './FloorPlan';
import { MobileFloorPlan } from './MobileFloorPlan';
import { generateUUID } from '../utils/uuid';
import { SaveLayoutModal } from './SaveLayoutModal';
import { AISeatingPlanner } from './AISeatingPlanner';
import { MainHeader } from './MainHeader';
import { SecondaryHeader } from './SecondaryHeader';
import { useAuth } from '../contexts/AuthContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// **New Imports for Subscription Logic**
import UpgradeModal from '../components/UpgradeModal';
import usePremium from '../hooks/usePremium';

const tableTypes = [
  { type: '1-sided', label: '1 Sided', defaultSeats: 8 },
  { type: 'round', label: 'Round', defaultSeats: 8 },
  { type: '2-sided', label: '2 Sided', defaultSeats: 8 },
  { type: '4-sided', label: '4 Sided', defaultSeats: 10 },
];

export const TableEditor = ({ isMobileView, onBack }) => {
  const { currentEvent, addTable, setCurrentEvent } = useStore();
  const [showTableConfig, setShowTableConfig] = useState(
    !currentEvent?.tables?.length || !currentEvent?.isLoadedLayout
  );
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showAIPlanner, setShowAIPlanner] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const { currentUser } = useAuth();
  const [tables, setTables] = useState({
    '1-sided': { count: 1, seats: 8 },
    round: { count: 6, seats: 8 },
    '2-sided': { count: 0, seats: 8 },
    '4-sided': { count: 0, seats: 10 },
  });

  // **Subscription Logic Hooks and State**
  const { isPremium, premiumLoading } = usePremium();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const handleExport = async () => {
    const element = document.getElementById('floor-plan');
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        backgroundColor: '#FFF9F0',
        scale: 2,
      });

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height
      );

      pdf.save(`${currentEvent?.name || 'seating-chart'}.pdf`);
    } catch (error) {
      console.error('Error exporting layout:', error);
    }
  };

  const handleEmail = () => {
    const subject = encodeURIComponent(
      `Seating Chart - ${currentEvent?.name || 'Layout'}`
    );
    const body = encodeURIComponent(
      `Here's the seating chart for ${currentEvent?.name || 'the event'}.\n\nTotal Tables: ${
        currentEvent?.tables?.length || 0
      }\nTotal Seats: ${
        currentEvent?.tables?.reduce((acc, table) => acc + table.seats, 0) || 0
      }`
    );

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const updateCount = (type, increment) => {
    setTables((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        count: Math.max(0, prev[type].count + (increment ? 1 : -1)),
      },
    }));
  };

  const updateSeats = (type, increment) => {
    setTables((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        seats: Math.max(1, prev[type].seats + (increment ? 1 : -1)),
      },
    }));
  };

  const handleAddTables = () => {
    const gridSize = isMobileView ? 120 : 160;
    const margin = 40;
    let currentX = margin;
    let currentY = margin;
    const maxWidth = window.innerWidth - margin * 2;

    Object.entries(tables).forEach(([type, config]) => {
      for (let i = 0; i < config.count; i++) {
        const table = {
          id: generateUUID(),
          type: type,
          seats: config.seats,
          position: { x: currentX, y: currentY },
          guests: [],
          rotation: 0,
        };

        addTable(table);

        currentX += gridSize + margin;
        if (currentX + gridSize > maxWidth) {
          currentX = margin;
          currentY += gridSize + margin;
        }
      }
    });

    setShowTableConfig(false);
  };

  const handleBack = () => {
    if (
      window.confirm(
        'Going back will clear your current layout. Are you sure?'
      )
    ) {
      setCurrentEvent(null);
      onBack?.();
    }
  };

  const handleAIPlannerClick = () => {
    if (!isPremium && !premiumLoading) {
      setShowUpgradeModal(true);
      return;
    }
    setShowAIPlanner(true);
  };

  // **Define handleReset Function**
  const handleReset = () => {
    if (
      window.confirm(
        'Are you sure you want to reset your current layout? This action cannot be undone.'
      )
    ) {
      // Reset tables state to initial values
      setTables({
        '1-sided': { count: 1, seats: 8 },
        round: { count: 6, seats: 8 },
        '2-sided': { count: 0, seats: 8 },
        '4-sided': { count: 0, seats: 10 },
      });

      // Clear tables from the store
      setCurrentEvent(null);

      // Show the table configuration panel again
      setShowTableConfig(true);
    }
  };

  if (showAIPlanner) {
    return <AISeatingPlanner onBack={() => setShowAIPlanner(false)} />;
  }

  return (
    <>
      <MainHeader />
      <SecondaryHeader
        currentEventName={currentEvent?.name}
        onBack={handleBack}
        onReset={handleReset}
        onAIPlanner={handleAIPlannerClick}
        onSave={() => setShowSaveModal(true)}
        onEmail={handleEmail}
        onExport={handleExport}
        onToggleGrid={() => setShowGrid(!showGrid)}
        showGrid={showGrid}
      />

      <div className="fixed inset-0 overflow-y-auto bg-[#FFF9F0]" style={{ top: '140px', paddingBottom: '140px' }}>
        {showTableConfig ? (
          <div className="max-w-md mx-auto p-4 md:p-6 mt-4">
            <h2 className="text-2xl font-serif text-[#646E78] mb-2 text-center">
              Design Your Layout
            </h2>
            <p className="text-sm md:text-base text-gray-600 mb-6 text-center">
              Add tables to get started
            </p>

            <div className="space-y-4 md:space-y-6 bg-white p-4 md:p-6 rounded-lg border-2 border-[#F4E1B2] shadow-md">
              <div className="grid grid-cols-3 gap-4 mb-2 text-sm font-medium text-[#646E78]">
                <span>Type</span>
                <span className="text-center">Tables</span>
                <span className="text-center">Guests</span>
              </div>

              {tableTypes.map(({ type, label }) => (
                <div
                  key={type}
                  className="grid grid-cols-3 gap-4 items-center"
                >
                  <span className="text-sm font-serif text-[#646E78]">
                    {label}
                  </span>

                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => updateCount(type, false)}
                      className="p-1.5 hover:bg-[#F4E1B2]/20 rounded"
                      type="button"
                      aria-label={`Decrease number of ${label} tables`}
                    >
                      <Minus className="w-4 h-4 text-[#646E78]" />
                    </button>
                    <span className="w-6 text-center text-sm text-[#646E78]">
                      {tables[type].count}
                    </span>
                    <button
                      onClick={() => updateCount(type, true)}
                      className="p-1.5 hover:bg-[#F4E1B2]/20 rounded"
                      type="button"
                      aria-label={`Increase number of ${label} tables`}
                    >
                      <Plus className="w-4 h-4 text-[#646E78]" />
                    </button>
                  </div>

                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => updateSeats(type, false)}
                      className="p-1.5 hover:bg-[#F4E1B2]/20 rounded"
                      type="button"
                      aria-label={`Decrease number of seats for ${label} tables`}
                    >
                      <Minus className="w-4 h-4 text-[#646E78]" />
                    </button>
                    <span className="w-6 text-center text-sm text-[#646E78]">
                      {tables[type].seats}
                    </span>
                    <button
                      onClick={() => updateSeats(type, true)}
                      className="p-1.5 hover:bg-[#F4E1B2]/20 rounded"
                      type="button"
                      aria-label={`Increase number of seats for ${label} tables`}
                    >
                      <Plus className="w-4 h-4 text-[#646E78]" />
                    </button>
                  </div>
                </div>
              ))}

              <div className="mt-6 md:mt-8 pt-4 border-t border-[#F4E1B2]">
                <p className="text-xs md:text-sm text-gray-600 mb-4 text-center">
                  {Object.values(tables).reduce(
                    (acc, { count, seats }) => acc + count * seats,
                    0
                  )}{' '}
                  total seats
                </p>
                <button
                  onClick={handleAddTables}
                  className="w-full bg-gradient-to-r from-[#F4E1B2] to-[#E5C594] text-[#646E78] py-3 rounded-full hover:opacity-90 transition-opacity text-sm md:text-base font-serif shadow-md"
                  type="button"
                >
                  Create Layout
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full p-4 md:p-6">
            {/* Action Buttons - Only show on mobile */}
            {isMobileView && (
              <div className="mb-6 flex flex-wrap gap-3 justify-center">
                <button
                  onClick={() => setShowGrid(!showGrid)}
                  className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-sm"
                >
                  <Grid className="w-4 h-4" />
                  {showGrid ? 'Hide Grid' : 'Show Grid'}
                </button>
                
                <button
                  onClick={handleAIPlannerClick}
                  className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-sm"
                >
                  <Brain className="w-4 h-4" />
                  AI Planner
                </button>

                <button
                  onClick={() => setShowSaveModal(true)}
                  className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-sm"
                >
                  <Save className="w-4 h-4" />
                  Save Layout
                </button>

                <button
                  onClick={handleExport}
                  className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-sm"
                >
                  <Download className="w-4 h-4" />
                  Download PDF
                </button>

                <button
                  onClick={handleEmail}
                  className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-sm"
                >
                  <Mail className="w-4 h-4" />
                  Email
                </button>
              </div>
            )}

            {/* Floor Plan */}
            <div 
              id="floor-plan"
              className="relative bg-white rounded-xl shadow-lg overflow-hidden" 
              style={{ height: isMobileView ? 'calc(100vh - 280px)' : 'calc(100vh - 240px)' }}
            >
              {isMobileView ? (
                <MobileFloorPlan showGrid={showGrid} />
              ) : (
                <FloorPlan showGrid={showGrid} />
              )}
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {showSaveModal && (
        <SaveLayoutModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          currentLayout={currentEvent}
        />
      )}

      {showAIPlanner && (
        <AISeatingPlanner onBack={() => setShowAIPlanner(false)} />
      )}

      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        onUpgrade={() => (window.location.href = '/subscribe')}
      />
    </>
  );
};
