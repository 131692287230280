// src/pages/SubscribePage.jsx

import React from 'react';
import SubscribeButton from '../components/SubscribeButton';
import { useAuth } from '../contexts/AuthContext';
import UpgradeModal from '../components/UpgradeModal';
import usePremium from '../hooks/usePremium';

const SubscribePage = () => {
  const { currentUser, loading } = useAuth();
  const { isPremium, premiumLoading } = usePremium();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (window.location.pathname !== '/subscribe' && currentUser && !isPremium && !premiumLoading) {
      setIsUpgradeModalOpen(true);
    }
  }, [currentUser, isPremium, premiumLoading]);

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  if (!currentUser) {
    return <div className="text-center mt-10">Please log in to subscribe.</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-semibold mb-4 text-center">Subscribe to Premium</h1>
        <p className="text-gray-600 mb-6 text-center">
          Unlock exclusive features by subscribing to our premium plan.
        </p>
        <SubscribeButton user={currentUser} />
      </div>

      {/* Upgrade Modal if already premium */}
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        onUpgrade={() => {
          setIsUpgradeModalOpen(false);
          window.location.href = '/subscribe';
        }}
      />
    </div>
  );
};

export default SubscribePage;
