import React from 'react';
import { Brain, Save, Mail, Download, Grid } from 'lucide-react';

export const MobileActionBar = ({ 
  showGrid,
  onToggleGrid,
  onAIPlanner,
  onSave,
  onEmail,
  onExport 
}) => {
  return (
    <div className="fixed top-0 left-0 right-0 z-20 bg-gradient-to-r from-[#F9E9D2] via-[#EAD4E2] to-[#F4E1B2] shadow-sm px-4 py-2">
      <div className="flex items-center justify-evenly">
        <button
          onClick={onToggleGrid}
          className="flex items-center justify-center w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
          title="Toggle Grid"
        >
          <Grid size={20} strokeWidth={1.5} className="text-[#4A3B52]" />
        </button>

        <button
          onClick={onAIPlanner}
          className="flex items-center justify-center w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
          title="AI Planner"
        >
          <Brain size={20} strokeWidth={1.5} className="text-[#4A3B52]" />
        </button>

        <button
          onClick={onSave}
          className="flex items-center justify-center w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
          title="Save Layout"
        >
          <Save size={20} strokeWidth={1.5} className="text-[#4A3B52]" />
        </button>

        <button
          onClick={onEmail}
          className="flex items-center justify-center w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
          title="Email"
        >
          <Mail size={20} strokeWidth={1.5} className="text-[#4A3B52]" />
        </button>

        <button
          onClick={onExport}
          className="flex items-center justify-center w-10 h-10 bg-white/90 hover:bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
          title="Export PDF"
        >
          <Download size={20} strokeWidth={1.5} className="text-[#4A3B52]" />
        </button>
      </div>
    </div>
  );
};
