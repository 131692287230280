import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  updateProfile,
  setPersistence,
  browserSessionPersistence // Import browser session persistence
} from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { toast } from 'react-hot-toast';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signup = async (email, password, firstName, lastName) => {
    try {
      // Set session persistence
      await setPersistence(auth, browserSessionPersistence);

      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (!user) throw new Error('User creation failed. No user object returned.');

      // Set the display name
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });

      // Call backend to check or create Firestore document
      const response = await fetch('/api/create-user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          firstName,
          lastName,
          merge: true, // Indicate merging logic in backend
        }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Error from backend: ${errorDetails.error || 'Unknown error'}`);
      }

      toast.success('Account created successfully!');
    } catch (error) {
      console.error('Error in signup:', error.message);
      toast.error('Failed to create account');
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      // Set session persistence
      await setPersistence(auth, browserSessionPersistence);

      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Logged in successfully!');
    } catch (error) {
      console.error('Error in login:', error.message);
      toast.error('Failed to log in');
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      // Set session persistence
      await setPersistence(auth, browserSessionPersistence);

      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (!user) throw new Error('Google login failed. No user object returned.');

      // Call backend to check or create Firestore document
      const response = await fetch('/api/create-user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          firstName: user.displayName.split(' ')[0] || 'Unknown',
          lastName: user.displayName.split(' ').slice(1).join(' ') || 'User',
          merge: true, // Indicate merging logic in backend
        }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Error from backend: ${errorDetails.error || 'Unknown error'}`);
      }

      toast.success('Logged in with Google successfully!');
    } catch (error) {
      console.error('Error in Google login:', error.message);
      toast.error('Failed to log in with Google');
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully!');
    } catch (error) {
      console.error('Error in logout:', error.message);
      toast.error('Failed to log out');
      throw error;
    }
  };

  const value = {
    currentUser,
    signup,
    login,
    loginWithGoogle,
    logout,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
