// src/pages/SuccessPage.jsx

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');

    const fetchSession = async () => {
      if (sessionId && currentUser) {
        try {
          // Optionally, verify the session on the server-side
          // For simplicity, we'll assume the webhook has handled the subscription
          toast.success('Subscription successful!');
          navigate('/'); // Redirect to home or dashboard
        } catch (error) {
          console.error('Error verifying session:', error);
          toast.error('Subscription verification failed.');
          navigate('/');
        }
      }
    };

    fetchSession();
  }, [location.search, currentUser, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-green-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-semibold text-green-600 mb-4">Subscription Successful!</h1>
        <p className="text-gray-700 mb-6">
          Thank you for subscribing to our Premium plan. You now have access to all premium features.
        </p>
        <button
          onClick={() => navigate('/')}
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
