import React, { useState } from 'react';
import { useStore } from '../store/useStore';
import { generateUUID } from '../utils/uuid';
import { Heart, Save, FolderOpen } from 'lucide-react';
import { SaveLayoutModal } from './SaveLayoutModal';
import { SavedLayouts } from './SavedLayouts';
import { useAuth } from '../contexts/AuthContext';

const eventTypes = [
  'Reception',
  'Quinceañera/Sweet 16',
  'Rehearsal Dinner',
  'Corporate Banquet',
  'Other'
];

export const EventSelector = () => {
  const { addEvent, setCurrentEvent, currentEvent } = useStore();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showSavedLayouts, setShowSavedLayouts] = useState(false);
  const { currentUser } = useAuth();

  const handleEventSelect = (eventName) => {
    const newEvent = {
      id: generateUUID(),
      name: eventName,
      tables: [],
      furniture: []
    };
    addEvent(newEvent);
    setCurrentEvent(newEvent);
  };

  if (showSavedLayouts) {
    return (
      <SavedLayouts
        onBack={() => setShowSavedLayouts(false)}
        onSelectLayout={() => setShowSavedLayouts(false)}
      />
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Banner Section */}
      <div className="text-center mb-16">
        <div className="flex items-center justify-center gap-3 mb-6">
          <Heart className="w-8 h-8 text-[#D3A6B8]" />
          <h1 className="text-4xl md:text-5xl font-serif text-[#4A3B52]">
            Welcome to Your Event Planner
          </h1>
        </div>
        <p className="text-xl text-[#6B5A74] max-w-2xl mx-auto mb-8 font-serif">
          Select your event type to get started with crafting the perfect seating layout for your special day.
        </p>
      </div>

      {/* User Actions */}
      {currentUser && (
        <div className="flex gap-3 mb-6 justify-center">
          <button
            onClick={() => setShowSavedLayouts(true)}
            className="flex items-center gap-2 px-6 py-3 bg-white border-2 border-[#F4E1B2] rounded-lg hover:bg-[#F4E1B2]/10 transition-colors font-serif text-[#646E78] shadow-lg"
          >
            <FolderOpen className="w-5 h-5 text-[#D3A6B8]" />
            Open Saved Layouts
          </button>
          {currentEvent && (
            <button
              onClick={() => setShowSaveModal(true)}
              className="flex items-center gap-2 px-6 py-3 bg-white border-2 border-[#F4E1B2] rounded-lg hover:bg-[#F4E1B2]/10 transition-colors font-serif text-[#646E78] shadow-lg"
            >
              <Save className="w-5 h-5 text-[#D3A6B8]" />
              Save Current Layout
            </button>
          )}
        </div>
      )}

      {/* Event Type Selection */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {eventTypes.map((eventName) => (
          <button
            key={eventName}
            onClick={() => handleEventSelect(eventName)}
            className="w-full p-6 text-center bg-white border-2 border-[#F4E1B2] rounded-xl hover:bg-[#F4E1B2]/10 transition-transform transform hover:-translate-y-1 shadow-md font-serif text-[#4A3B52] text-lg"
          >
            {eventName}
          </button>
        ))}
      </div>

      {/* Save Layout Modal */}
      {currentEvent && (
        <SaveLayoutModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          currentLayout={currentEvent}
        />
      )}
    </div>
  );
};
