// FloorPlan.jsx

import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core';
import { Table } from './Table';
import { Furniture } from './Furniture';
import { Toolbar } from './Toolbar';
import { useStore } from '../store/useStore';
import { SecondaryHeader } from './SecondaryHeader';
import { useNavigate } from 'react-router-dom';
import { SaveLayoutModal } from './SaveLayoutModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const GRID_SIZE = 20;

const MemoizedSecondaryHeader = memo(SecondaryHeader);

export const FloorPlan = () => {
  const { currentEvent, updateTable, updateFurniture } = useStore();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showGrid, setShowGrid] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [lastPanPosition, setLastPanPosition] = useState({ x: 0, y: 0 });
  const [showSaveModal, setShowSaveModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
        delay: 0,
        tolerance: 5,
      },
    })
  );

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        // Adjust for both headers (104px) and padding
        const viewportHeight = window.innerHeight - 104 - 32;
        const viewportWidth = window.innerWidth - 48;
        setDimensions({
          width: viewportWidth,
          height: viewportHeight,
        });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Handle Wheel for Zooming
  const handleWheel = useCallback((e) => {
    e.preventDefault(); // Prevent default scrolling
    const delta = e.deltaY;
    setScale((prevScale) => {
      const newScale = prevScale - delta * 0.001;
      return Math.min(Math.max(newScale, 0.5), 3); // Restrict zoom between 50% and 300%
    });
  }, []);

  // Handle Mouse Down for Panning
  const handleMouseDown = useCallback((e) => {
    if (e.target === containerRef.current) {
      setIsPanning(true);
      setLastPanPosition({ x: e.clientX, y: e.clientY });
    }
  }, []);

  // Handle Mouse Move for Panning
  const handleMouseMove = useCallback((e) => {
    if (isPanning) {
      const deltaX = e.clientX - lastPanPosition.x;
      const deltaY = e.clientY - lastPanPosition.y;
      setPan((prevPan) => ({
        x: prevPan.x + deltaX,
        y: prevPan.y + deltaY,
      }));
      setLastPanPosition({ x: e.clientX, y: e.clientY });
    }
  }, [isPanning, lastPanPosition]);

  // Handle Mouse Up to Stop Panning
  const handleMouseUp = useCallback(() => {
    setIsPanning(false);
  }, []);

  // Handle Drag Start to Differentiate from Panning
  const handleDragStart = useCallback(() => {
    setIsDragging(true);
    setIsPanning(false); // Prevent conflict with panning
  }, []);

  // Handle Drag End to Update Positions
  const handleDragEnd = useCallback((event) => {
    const { active, delta } = event;
    const id = active.id;
    setIsDragging(false);

    // Calculate new position without snapping
    const newX = active.data.current.position.x + delta.x / scale;
    const newY = active.data.current.position.y + delta.y / scale;

    const table = currentEvent?.tables.find((t) => t.id === id);
    if (table) {
      const tableWidth =
        table.type === '1-sided' || table.type === '2-sided' ? 192 : 128;
      const tableHeight =
        table.type === '2-sided'
          ? 128
          : table.type === '1-sided'
          ? 96
          : 128;

      // Calculate maximum allowed positions
      const maxX = dimensions.width / scale - tableWidth;
      const maxY = dimensions.height / scale - tableHeight;

      updateTable(id, {
        position: {
          x: Math.min(Math.max(0, newX), maxX),
          y: Math.min(Math.max(0, newY), maxY),
        },
      });
      return;
    }

    const furniture = currentEvent?.furniture?.find((f) => f.id === id);
    if (furniture) {
      const newX = furniture.position.x + delta.x / scale;
      const newY = furniture.position.y + delta.y / scale;

      const maxX = dimensions.width / scale - furniture.size.width;
      const maxY = dimensions.height / scale - furniture.size.height;

      updateFurniture(id, {
        position: {
          x: Math.min(Math.max(0, newX), maxX),
          y: Math.min(Math.max(0, newY), maxY),
        },
      });
    }
  }, [currentEvent, scale, dimensions, updateTable, updateFurniture]);

  const handleExport = useCallback(async () => {
    const element = document.getElementById('floor-plan');
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        backgroundColor: '#FFF9F0',
        scale: 2,
      });

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height
      );

      pdf.save(`${currentEvent?.name || 'seating-chart'}.pdf`);
    } catch (error) {
      console.error('Error exporting layout:', error);
    }
  }, [currentEvent]);

  const handleEmail = useCallback(() => {
    const subject = encodeURIComponent(
      `Seating Chart - ${currentEvent?.name || 'Layout'}`
    );
    const body = encodeURIComponent(
      `Here's the seating chart for ${currentEvent?.name || 'the event'}.\n\nTotal Tables: ${
        currentEvent?.tables?.length || 0
      }\nTotal Seats: ${
        currentEvent?.tables?.reduce((acc, table) => acc + table.seats, 0) || 0
      }`
    );

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  }, [currentEvent]);

  const updatePosition = useCallback((element, x, y) => {
    requestAnimationFrame(() => {
      element.style.transform = `translate(${x}px, ${y}px)`;
    });
  }, []);

  return (
    <div className="flex flex-col w-full">
      <MemoizedSecondaryHeader
        currentEventName={currentEvent?.name}
        onBack={() => navigate('/planner')}
        onReset={() => {
          setPan({ x: 0, y: 0 });
          setScale(1);
        }}
        onAIPlanner={() => navigate('/ai-planner')}
        onSave={() => setShowSaveModal(true)}
        onEmail={handleEmail}
        onExport={handleExport}
      />
      <div className="flex-1 overflow-hidden">
        <div
          className="w-full h-full flex items-center justify-center p-4 bg-gradient-to-br from-[#FDF8F0] to-[#FCF3E6]"
          onWheel={handleWheel}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div
            ref={containerRef}
            id="floor-plan"
            className="bg-[#FFF9F0] rounded-lg shadow-lg relative overflow-hidden"
            style={{
              width: `${dimensions.width}px`,
              height: `${dimensions.height}px`,
              cursor: isPanning ? 'grabbing' : isDragging ? 'grabbing' : 'default',
            }}
            onMouseDown={handleMouseDown}
          >
            <div
              className="inner-scale-wrapper"
              style={{
                transform: `translate(${pan.x}px, ${pan.y}px) scale(${scale})`,
                transformOrigin: '0 0',
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
                backgroundSize: `${GRID_SIZE / scale}px ${GRID_SIZE / scale}px`, // Dynamically adjust grid size
                backgroundImage: showGrid
                  ? `
                    linear-gradient(to right, rgba(211, 166, 184, 0.1) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(211, 166, 184, 0.1) 1px, transparent 1px)
                  `
                  : 'none',
                backgroundRepeat: 'repeat',
                backgroundPosition: `${-pan.x / scale}px ${-pan.y / scale}px`, // Ensure grid stays aligned while panning
              }}
            >
              <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                {currentEvent?.tables.map((table, index) => (
                  <Table key={table.id} table={table} tableNumber={index + 1} />
                ))}
                {currentEvent?.furniture?.map((item) => (
                  <Furniture key={item.id} item={item} />
                ))}
              </DndContext>
            </div>
          </div>
          <Toolbar onToggleGrid={() => setShowGrid(!showGrid)} showGrid={showGrid} />
        </div>
      </div>
      {showSaveModal && (
        <SaveLayoutModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          currentLayout={currentEvent}
        />
      )}
    </div>
  );
};

export default memo(FloorPlan);