import React from 'react';
import { Helmet } from 'react-helmet';

export const StructuredData = () => {
  const structuredData = [
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "Seat Your Guest - Wedding Seating Planner",
      "applicationCategory": "WebApplication",
      "operatingSystem": "Web Browser",
      "description": "AI-powered wedding seating chart planner that helps you create the perfect seating arrangement for your wedding. Features include drag-and-drop interface, smart guest management, and automatic seating optimization.",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "ratingCount": "50"
      },
      "featureList": [
        "AI-powered seating optimization",
        "Drag-and-drop interface",
        "Multiple table layouts",
        "Guest relationship management",
        "Export to PDF",
        "Email sharing"
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "WebApplication",
      "name": "Seat Your Guest",
      "url": "https://www.seatyourguests.com",
      "applicationCategory": "Wedding Planning Tool",
      "browserRequirements": "Requires JavaScript. Works on all modern browsers.",
      "permissions": "internet",
      "softwareVersion": "1.0",
      "keywords": "wedding seating chart, wedding table planner, seating arrangement, wedding planning tool, AI seating planner"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How does the AI seating planner work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our AI seating planner uses advanced algorithms to automatically arrange guests based on their relationships, preferences, and table dynamics to create the optimal seating arrangement for your wedding."
        }
      }, {
        "@type": "Question",
        "name": "Is Seat Your Guest free to use?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Seat Your Guest offers a free plan with essential features. Premium features are available through our subscription plans."
        }
      }, {
        "@type": "Question",
        "name": "Can I export my seating plan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can export your seating plan to PDF format and share it via email with your wedding planners or venue."
        }
      }]
    }
  ];

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};
