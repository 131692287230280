// src/components/MobileFloorPlan.jsx
import React, { useRef, useState, useEffect } from 'react';
import { useStore } from '../store/useStore';
import { DndContext, useSensor, useSensors, TouchSensor, PointerSensor } from '@dnd-kit/core';
import { ZoomIn, ZoomOut, RotateCw, RotateCcw, RefreshCw } from 'lucide-react';
import { Table } from './Table';
import { Furniture } from './Furniture';
import { MobileActionBar } from './MobileActionBar';

const MIN_SCALE = 0.5;
const MAX_SCALE = 3;
const GRID_SIZE = 20;

const MobileFloorControls = ({ onZoomIn, onZoomOut, onReset }) => (
  <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-white/90 rounded-full shadow-lg px-4 py-2 flex items-center gap-3 z-50">
    <button
      onClick={onZoomOut}
      className="p-2 hover:bg-gray-100 rounded-full"
      title="Zoom Out"
    >
      <ZoomOut className="w-5 h-5 text-[#D3A6B8]" />
    </button>
    
    <button
      onClick={onZoomIn}
      className="p-2 hover:bg-gray-100 rounded-full"
      title="Zoom In"
    >
      <ZoomIn className="w-5 h-5 text-[#D3A6B8]" />
    </button>

    <div className="w-px h-6 bg-gray-200" />

    <button
      onClick={onReset}
      className="p-2 hover:bg-gray-100 rounded-full"
      title="Reset View"
    >
      <RefreshCw className="w-5 h-5 text-[#D3A6B8]" />
    </button>
  </div>
);

export const MobileFloorPlan = () => {
  const { currentEvent, updateTable, updateFurniture } = useStore();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0});
  const [selectedItem, setSelectedItem] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const lastPanRef = useRef({ x: 0, y: 0 });
  const touchStartRef = useRef({ x: 0, y: 0, time: 0 });
  const isPanningRef = useRef(false);

  // Enhanced touch sensors
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      touchStartRef.current = {
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2,
        distance,
        scale,
        time: Date.now(),
      };
    } else if (e.touches.length === 1) {
      touchStartRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
        time: Date.now(),
      };
    }
  };

  const handleTouchMove = (e) => {
    if (selectedItem) return;

    if (e.touches.length === 2) {
      e.preventDefault();
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      
      const newScale = Math.min(
        MAX_SCALE,
        Math.max(
          MIN_SCALE,
          (touchStartRef.current.scale * distance) / touchStartRef.current.distance
        )
      );
      
      setScale(newScale);

      const midX = (touch1.clientX + touch2.clientX) / 2;
      const midY = (touch1.clientY + touch2.clientY) / 2;
      const deltaX = midX - touchStartRef.current.x;
      const deltaY = midY - touchStartRef.current.y;

      setPan(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));

      touchStartRef.current.x = midX;
      touchStartRef.current.y = midY;
    } else if (e.touches.length === 1 && !isPanningRef.current) {
      const deltaX = e.touches[0].clientX - touchStartRef.current.x;
      const deltaY = e.touches[0].clientY - touchStartRef.current.y;
      
      setPan(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));

      touchStartRef.current.x = e.touches[0].clientX;
      touchStartRef.current.y = e.touches[0].clientY;
    }
  };

  const handleDragEnd = (event) => {
    const { active, delta } = event;
    const item = active.data.current;
    
    if (item.type === 'table') {
      updateTable(item.id, {
        position: {
          x: item.position.x + delta.x / scale,
          y: item.position.y + delta.y / scale,
        },
      });
    } else if (item.type === 'furniture') {
      updateFurniture(item.id, {
        position: {
          x: item.position.x + delta.x / scale,
          y: item.position.y + delta.y / scale,
        },
      });
    }
    
    setSelectedItem(null);
  };

  return (
    <>
      <MobileActionBar
        showGrid={showGrid}
        onToggleGrid={() => setShowGrid(!showGrid)}
        onAIPlanner={() => {/* Add AI planner handler */}}
        onSave={() => {/* Add save handler */}}
        onEmail={() => {/* Add email handler */}}
        onExport={() => {/* Add export handler */}}
      />
      <div 
        ref={containerRef}
        className="relative w-full h-full overflow-hidden touch-none pt-12"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
        >
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              transform: `scale(${scale}) translate(${pan.x}px, ${pan.y}px)`,
              transformOrigin: '0 0',
            }}
          >
            {/* Grid Background */}
            {showGrid && (
              <div 
                className="absolute top-0 left-0 w-full h-full"
                style={{
                  backgroundImage: 'radial-gradient(circle, #ddd 1px, transparent 1px)',
                  backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
                  opacity: 0.5,
                }}
              />
            )}

            {/* Tables */}
            {currentEvent?.tables?.map((table) => (
              <Table
                key={table.id}
                table={table}
                scale={scale}
                onSelect={() => setSelectedItem({ type: 'table', ...table })}
                isSelected={selectedItem?.id === table.id}
              />
            ))}

            {/* Furniture */}
            {currentEvent?.furniture?.map((item) => (
              <Furniture
                key={item.id}
                item={item}
                scale={scale}
                onSelect={() => setSelectedItem({ type: 'furniture', ...item })}
                isSelected={selectedItem?.id === item.id}
              />
            ))}
          </div>
        </DndContext>

        {selectedItem && (
          <div className="fixed top-20 left-1/2 -translate-x-1/2 bg-white/90 rounded-full shadow-lg px-4 py-2 flex items-center gap-3 z-50">
            <button
              onClick={() => {
                const newRotation = (selectedItem.rotation || 0) - 90;
                if (selectedItem.type === 'table') {
                  updateTable(selectedItem.id, { rotation: newRotation });
                } else {
                  updateFurniture(selectedItem.id, { rotation: newRotation });
                }
              }}
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Rotate Left"
            >
              <RotateCcw className="w-5 h-5 text-[#D3A6B8]" />
            </button>
            
            <button
              onClick={() => {
                const newRotation = (selectedItem.rotation || 0) + 90;
                if (selectedItem.type === 'table') {
                  updateTable(selectedItem.id, { rotation: newRotation });
                } else {
                  updateFurniture(selectedItem.id, { rotation: newRotation });
                }
              }}
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Rotate Right"
            >
              <RotateCw className="w-5 h-5 text-[#D3A6B8]" />
            </button>
          </div>
        )}

        <MobileFloorControls
          onZoomIn={() => setScale(prev => Math.min(MAX_SCALE, prev * 1.2))}
          onZoomOut={() => setScale(prev => Math.max(MIN_SCALE, prev / 1.2))}
          onReset={() => {
            setScale(1);
            setPan({ x: 0, y: 0 });
          }}
        />
      </div>
    </>
  );
};
