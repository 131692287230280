// src/hooks/usePremium.js

import { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const usePremium = () => {
  const { currentUser } = useAuth();
  const [isPremium, setIsPremium] = useState(false);
  const [premiumLoading, setPremiumLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      setIsPremium(false);
      setPremiumLoading(false);
      return;
    }

    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);

    const unsubscribe = onSnapshot(userRef, (docSnap) => {
      if (docSnap.exists()) {
        setIsPremium(docSnap.data().isPremium || false);
      } else {
        setIsPremium(false);
      }
      setPremiumLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return { isPremium, premiumLoading };
};

export default usePremium;