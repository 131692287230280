// src/components/SecondaryHeader.jsx

import React, { useState, useEffect } from "react";
import { ArrowLeft, Brain, Save, Mail, Download, RotateCcw } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const SecondaryHeader = ({ currentEventName, onBack, onReset, onAIPlanner, onSave, onEmail, onExport }) => {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobileView) return null;

  return (
    <div className="fixed top-[84px] left-0 right-0 bg-white/95 border-b border-[#D3A6B8]/20 z-20 shadow-sm">
      <div className="max-w-7xl mx-auto px-6 py-2.5">
        <div className="flex items-center justify-between">
          {/* Left Section */}
          <div className="flex items-center gap-2 w-1/4">
            <button
              onClick={onBack}
              className="flex items-center gap-1.5 px-3 py-1.5 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
            >
              <ArrowLeft className="w-4 h-4 text-[#D3A6B8]" />
              Back
            </button>
            <button
              onClick={onReset}
              className="flex items-center gap-1.5 px-3 py-1.5 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
              title="Reset"
            >
              <RotateCcw className="w-4 h-4 text-[#D3A6B8]" />
              <span className="hidden sm:inline">Reset</span>
            </button>
          </div>

          {/* Center Section */}
          <div className="flex-1 flex justify-center">
            <h2 className="text-base font-serif text-[#4A3B52] bg-white/90 px-5 py-1.5 rounded-full shadow-sm border border-[#D3A6B8]/20 whitespace-nowrap">
              {currentEventName || "Untitled Event"}
            </h2>
          </div>

          {/* Right Section */}
          <div className="flex items-center justify-end gap-1.5 w-1/4">
            <button
              onClick={onAIPlanner}
              className="flex items-center gap-1.5 px-3 py-2 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
              title="AI Planner"
            >
              <Brain className="w-4 h-4 text-[#D3A6B8]" />
              <span className="hidden sm:inline">AI&nbsp;Planner</span>
            </button>
            <button
              onClick={onSave}
              className="flex items-center gap-1.5 px-3 py-1.5 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
              title="Save"
            >
              <Save className="w-4 h-4 text-[#D3A6B8]" />
              <span className="hidden sm:inline">Save</span>
            </button>
            <button
              onClick={onEmail}
              className="flex items-center gap-1.5 px-3 py-1.5 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
              title="Email"
            >
              <Mail className="w-4 h-4 text-[#D3A6B8]" />
              <span className="hidden sm:inline">Email</span>
            </button>
            <button
              onClick={onExport}
              className="flex items-center gap-1.5 px-3 py-1.5 text-[#4A3B52] bg-white hover:bg-gray-50 rounded-full transition-all duration-300 text-sm border border-[#D3A6B8]/20 shadow-sm hover:shadow-md whitespace-nowrap"
              title="Export"
            >
              <Download className="w-4 h-4 text-[#D3A6B8]" />
              <span className="hidden sm:inline">Export</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};