// src/components/MainHeader.jsx

import React, { useState } from "react";
import { Heart, LogIn, LogOut, User, Home, Menu, X } from "lucide-react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { AuthModal } from "./AuthModal";

export const MainHeader = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-[#F9E9D2] via-[#EAD4E2] to-[#F4E1B2] shadow-lg z-30">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 py-2 sm:py-5 flex items-center justify-between">
        {/* Logo Section - Smaller on mobile */}
        <div className="flex items-center gap-1 sm:gap-3 bg-white/90 px-2 sm:px-5 py-1 sm:py-2.5 rounded-full shadow-md">
          <Heart className="w-4 h-4 sm:w-6 sm:h-6 text-[#D3A6B8]" />
          <h1 className="text-base sm:text-xl font-serif text-[#4A3B52]">SeatYourGuests</h1>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden p-1.5 rounded-lg hover:bg-white/10"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
        </button>

        {/* Desktop Auth Buttons */}
        <div className="hidden md:flex items-center gap-3">
          {currentUser ? (
            <>
              <span className="text-[#4A3B52] flex items-center gap-2 font-serif">
                <User size={16} className="text-[#D3A6B8]" />
                Hi, {currentUser.displayName?.split(" ")[0] || "there"}!
              </span>
              <button
                onClick={logout}
                className="flex items-center gap-2 bg-white/90 px-4 py-2 rounded-full shadow-md hover:shadow-lg transition-all"
              >
                <LogOut size={16} />
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsAuthModalOpen(true)}
              className="flex items-center gap-2 bg-white/90 px-4 py-2 rounded-full shadow-md hover:shadow-lg transition-all"
            >
              <LogIn size={16} />
              Login
            </button>
          )}
        </div>

        {/* Mobile Menu - Now as a minimal dropdown */}
        {isMobileMenuOpen && (
          <div className="absolute top-full right-0 mt-1 mr-2 bg-white rounded-lg shadow-lg overflow-hidden">
            {currentUser ? (
              <div className="p-2 min-w-[150px]">
                <div className="px-3 py-2 text-sm text-gray-600">
                  Hi, {currentUser.displayName?.split(" ")[0] || "there"}!
                </div>
                <button
                  onClick={() => {
                    logout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <LogOut size={14} />
                  Logout
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  setIsAuthModalOpen(true);
                  setIsMobileMenuOpen(false);
                }}
                className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <LogIn size={14} />
                Login
              </button>
            )}
          </div>
        )}
      </div>

      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} />
    </div>
  );
};
