import React from 'react';
import PropTypes from 'prop-types';

export function OptimizedImage({ src, alt, className, width, height, priority = false }) {
  return (
    <div className={`relative ${className}`} style={{ aspectRatio: width / height }}>
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        loading={priority ? "eager" : "lazy"}
        decoding={priority ? "sync" : "async"}
        className="w-full h-full object-cover"
        {...(priority && {
          fetchPriority: "high",
          importance: "high",
        })}
      />
    </div>
  );
}

OptimizedImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  priority: PropTypes.bool,
};

OptimizedImage.defaultProps = {
  className: '',
  priority: false,
};
