// src/components/SubscribeButton.jsx

import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

const SubscribeButton = ({ user }) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubscribe = async () => {
    if (!stripe) {
      setErrorMessage('Stripe has not loaded yet. Please try again later.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      // Send request to create a checkout session
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.email, uid: user.uid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }

      const { sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        setErrorMessage(error.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error('Error subscribing:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={handleSubscribe}
        disabled={!stripe || loading}
        className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-600 disabled:opacity-50 transition-colors"
        aria-label="Subscribe to Premium"
      >
        {loading ? 'Processing...' : 'Subscribe to Premium'}
      </button>
      {errorMessage && (
        <p className="mt-2 text-red-500 text-sm">{errorMessage}</p>
      )}
    </>
  );
};

export default SubscribeButton;
