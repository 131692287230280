import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, Users, ArrowRight, CheckCircle2 } from 'lucide-react';
import { OptimizedImage } from './OptimizedImage';

const FeatureCard = ({ title, description, image, priority = false }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <OptimizedImage
      src={image}
      alt={title}
      width={500}
      height={300}
      priority={priority}
      className="w-full"
    />
    <div className="p-6">
      <h3 className="text-xl font-serif text-[#4A3B52] mb-2">{title}</h3>
      <p className="text-[#6B5A74]">{description}</p>
    </div>
  </div>
);

const Step = ({ number, title, description }) => (
  <div className="flex items-start gap-4">
    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-[#D3A6B8] text-white flex items-center justify-center">
      {number}
    </div>
    <div>
      <h3 className="text-lg font-serif text-[#4A3B52] mb-1">{title}</h3>
      <p className="text-[#6B5A74]">{description}</p>
    </div>
  </div>
);

export const LandingPage = () => {
  const navigate = useNavigate();

  // Preload the LCP image
  React.useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = 'https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?auto=format&fit=crop&q=80&w=500';
    document.head.appendChild(link);
    return () => document.head.removeChild(link);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <div className="flex items-center justify-center gap-3 mb-6">
          <Heart className="w-8 h-8 text-[#D3A6B8]" />
          <h1 className="text-4xl md:text-5xl font-serif text-[#4A3B52]">
            Perfect Seating, Perfect Wedding
          </h1>
        </div>
        <p className="text-xl text-[#6B5A74] max-w-2xl mx-auto mb-8 font-serif">
          Create beautiful, intuitive seating arrangements for your special day with our elegant drag-and-drop seating chart planner.
        </p>
        <button
          onClick={() => navigate('/planner')}
          className="inline-flex items-center gap-2 px-8 py-4 bg-[#D3A6B8] text-white rounded-full hover:bg-[#C295A7] transition-all duration-300 shadow-lg hover:shadow-xl font-serif text-lg"
        >
          Let's Get Started
          <ArrowRight className="w-5 h-5" />
        </button>
      </div>

      {/* Features Grid */}
      <div className="grid md:grid-cols-3 gap-8 mb-16">
        <FeatureCard
          title="Drag & Drop Design"
          description="Effortlessly arrange tables and guests with our intuitive drag-and-drop interface."
          image="https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?auto=format&fit=crop&q=80&w=500"
          priority={true} // This is the LCP image
        />
        <FeatureCard
          title="Multiple Layouts"
          description="Save and manage different seating arrangements for various wedding events."
          image="https://images.unsplash.com/photo-1519225421980-715cb0215aed?auto=format&fit=crop&q=80&w=500"
        />
        <FeatureCard
          title="AI-Powered Seating"
          description="Import your guest list via CSV and let our AI create optimal seating based on relationships and preferences."
          image="https://images.unsplash.com/photo-1527525443983-6e60c75fff46?auto=format&fit=crop&q=80&w=500"
        />
      </div>

      {/* How It Works */}
      <div className="max-w-6xl mx-auto mb-16">
        <h2 className="text-3xl font-serif text-[#4A3B52] text-center mb-8">How It Works</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          <Step
            number="1"
            title="Create Event"
            description="Start by creating your wedding event and adding basic details."
          />
          <Step
            number="2"
            title="Add Guests"
            description="Import or manually add your guest list with preferences."
          />
          <Step
            number="3"
            title="Design Layout"
            description="Use our drag-and-drop interface to arrange tables."
          />
          <Step
            number="4"
            title="Assign Seats"
            description="Place guests at tables or let our AI optimize seating."
          />
        </div>
      </div>
    </div>
  );
};
